<template>
  <div
    class="w-full flex-shrink-0 lg:w-[300px] lg:mt-0 lg:pl-4 xl:pl-0 sticky h-fit transition-all ease-in-out duration-100"
    ref="sidebar"
    :class="{ 'top-0': !navigation_entity, 'top-[50px]': navigation_entity }"
  >
    <div class="space-y-6 flex flex-col sm:items-start items-center">
      <iframe
        :src="hotels"
        style="width: 300px; height: 250px"
        frameborder="0"
        scrolling="no"
        id="SB479250"
        title="tripcom-parnter-hotel"
        class="max-w-full"
        loading="lazy"
      ></iframe>
      <iframe
        :src="travels"
        style="width: 300px; height: 250px"
        frameborder="0"
        scrolling="no"
        class="max-w-full"
        loading="lazy"
        id="SB475722"
        title="tripcom-parnter-tour"
      ></iframe>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useConfigStore } from '~/stores/config';

const { navigation_entity } = storeToRefs(useConfigStore());

const { locale } = useI18n();
const hotels = computed(() => {
  if (locale.value == "en") {
    return "https://www.trip.com/partners/ad/SB479250?Allianceid=5002474&SID=90945751&trip_sub1=";
  }
  return "https://vn.trip.com/partners/ad/SB479250?Allianceid=5002474&SID=90945751&trip_sub1=";
});
const travels = computed(() => {
  if (locale.value == "en") {
    return "https://www.trip.com/partners/ad/SB475722?Allianceid=5002474&SID=90945751&trip_sub1=";
  }
  return "https://vn.trip.com/partners/ad/SB475722?Allianceid=5002474&SID=90945751&trip_sub1=";
});
</script>
