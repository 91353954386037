<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div
      class="overflow-hidden rounded-2xl lg:grid lg:h-36 lg:grid-cols-3 lg:gap-3 lg:overflow-visible lg:rounded-none space-y-4 lg:space-y-0"
    >
      <V2EntityBadgeRate></V2EntityBadgeRate>
      <V2EntityBadgeFacility></V2EntityBadgeFacility>
      <v2EntityBadgeLocation></v2EntityBadgeLocation>
    </div>
  </div>
</template>
