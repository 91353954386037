<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-50">
      <TransitionChild as="template" enter="duration-200 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed bottom-0 hidden md:block left-0 right-0 top-0 bg-black/40 opacity-100"
          @click="closeModal()" />
      </TransitionChild>

      <div class="fixed inset-x-0 bg-white  md:bg-transparent z-50 inset-y-0 overflow-y-scroll">
        <div class="md:flex min-h-full md:items-center md:justify-center md:p-2 lg:py-16">
          <div class="z-10 flex md:hidden sticky shrink-0 top-0 items-center bg-white border-b border-neutral-50 p-1">
            <button @click="closeModal()"
              class="inline-flex shrink-0 p-2 text-neutral-900 hover:text-neutral-700 focus:outline-none">
              <Icon name="solar:alt-arrow-left-outline" size="24"></Icon><span class="sr-only">Đóng</span>
            </button>
            <div @click="openLinkRoom" class="ml-auto w-fit font-semibold text-secondary-700 text-sm px-2">
              {{ $t("check_availibility") }}
            </div>
          </div>
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 translate-y-4"
            enter-to="opacity-100 translate-y-0" leave="duration-200 ease-in" leave-from="opacity-100 translate-y-0"
            leave-to="opacity-0 translate-y-4">
            <DialogPanel
              class="relative mx-auto min-h-0 overflow-hidden md:rounded-xl bg-white  shadow-2xl transform-gpu">
              <button
                class="absolute z-50 top-2 md:top-4 right-2 md:right-4 cursor-pointer rounded-full md:block hidden"
                @click="closeModal">
                <IconX class="text-neutral-600 w-5" size="20" />
              </button>

              <div class="w-screen max-w-3xl lg:max-w-4xl xl:max-w-5xl">
                <V2SkeletonRoom v-if="loading"></V2SkeletonRoom>
                <div class="relative grid grid-cols-5" v-else>
                  <div class="col-span-12 md:col-span-3">
                    <CoreRoomGallery :gallery="room?.gallery" :alt="room?.name" />
                  </div>
                  <div class="relative col-span-12 md:col-span-2" v-if="transRoom">
                    <div class="static md:absolute inset-x-0 inset-y-0 flex flex-col">
                      <div class="absolute left-0 right-0 top-0 z-10 h-6 bg-gradient-to-b from-white to-white/0"></div>
                      <div class="relative grow overflow-y-auto overflow-x-hidden px-4 bg-white"
                        :class="{ lenis: open }">
                        <div
                          class="flex text-xs font-semibold mt-6 px-2 h-6 rounded-md border bg-secondary-600 text-white items-center justify-center w-fit drop-shadow-sm">
                          <span>{{ room?.room_type?.name }}</span>
                        </div>
                        <h3 class="mb-2 pr-5 text-xl font-semibold text-neutral-900">
                          {{ transRoom?.name }}
                        </h3>

                        <div class="flex mb-3 flex-wrap gap-2 text-sm">
                          <div v-if="room?.bathroom"
                            class="flex text-sm text-neutral-800 items-center justify-center w-fit">
                            <Icon name="solar:bath-broken" size="18" class="mr-1" />
                            <span>{{ room?.bathroom }}</span>
                          </div>
                          <div v-if="room && room.room_size"
                            class="flex text-sm text-neutral-800 items-center justify-center w-fit">
                            <Icon name="solar:crop-minimalistic-broken" size="18" class="mr-1" />
                            <span v-html="renderRoomsize(room.room_size, room.unit ?? '')
                              "></span>
                          </div>
                        </div>
                        <div class="flex text-sm mb-3">
                          <span>{{
                            $t("room_guests", {
                              guests: room?.max_guests,
                              persons: room?.max_persons,
                            })
                          }}</span>
                        </div>
                        <div class="text-sm mb-3" v-html="transRoom?.description"></div>
                        <div class="flex flex-col text-sm mt-4">
                          <h4 class="font-semibold mb-2">
                            {{ $t("room_facilities") }}
                          </h4>
                          <div class="mt-4 mb-4">
                            <div class="-ml-4 -mt-2 flex flex-wrap text-neutral-800 lg:-ml-5 lg:-mt-4">
                              <span v-for="amenity in room?.room_facilities"
                                class="inline-flex items-center pl-4 pt-2 lg:pl-5 lg:pt-4">
                                <Icon name="material-symbols:check-rounded" class="mr-1 shrink-0 text-neutral-400"
                                  size="14"></Icon>
                                <span class="whitespace-nowrap text-xs">
                                  {{ amenity.name }}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="relative z-10 shrink-0 bg-white pl-6 pr-4">
                        <div class="absolute bottom-full left-0 right-0 h-6 bg-gradient-to-t from-white to-white/0">
                        </div>
                        <div class="flex justify-center md:justify-end border-t py-4">
                          <button type="button" @click="openLinkRoom"
                            class="inline-flex select-none items-center justify-center font-bold focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-200 bg-secondary-700 text-white hover:bg-secondary-600 focus-visible:bg-secondary-600 active:bg-secondary-700 disabled:bg-secondary-100  sm:text-base rounded-xl px-4 py-2 sm:px-6 sm:py-3.5 ">
                            {{ $t("check_availibility") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import useAffiliate from "~/composables/affiliate";
import { useHotelStore } from "~/stores/hotel";
import type { Room } from "~/types/Hotel";

const props = defineProps({
  open: {
    default: false,
    type: Boolean,
  },
  room: {
    type: Object as () => Room,
    required: true,
  },
});
const { locale } = useI18n();

const transRoom = computed(() => {
  if (props.room && props.room.translation && props.room.translation.length) {
    for (let i = 0; i < props.room?.translation.length; i++) {
      if (props.room?.translation[i].locale == locale.value) {
        return props.room?.translation[i];
      }
    }
  }
  return props.room;
});

const emit = defineEmits(["closeModal", "UpdateRoom"]);
const closeModal = () => {
  emit("closeModal");
};
const loading = ref(true);
const lenis = useLenis();

watch(
  () => props.open,
  () => {
    if (props.room && props.open && !props.room.description) {
      fetchRoom();
    } else {
      loading.value = false;
    }

    if (props.open) {
      lenis.instance.value.stop();
    } else {
      lenis.instance.value.start();
    }
  }
);
const { hotel } = useHotelStore();
const { linkRoom, redirect } = useBooking();

const HrefRoom = computed(() => {
  var path = hotel?.indent_str;
  if (!path) {
    path = "/searchresults";
  } else {
    path = "/hotel/vn/" + path;
  }
  return linkRoom(path, props.room?.roomId ?? "");
});
const { AffQuery } = useAffiliate()
const router = useRouter()
const localePath = useLocalePath()
const openLinkRoom = () => {
  return redirect(HrefRoom.value).then(() => {
    if (hotel?.indent_id) {
      const query = AffQuery({
        dest_id: hotel?.indent_id,
        dest_type: 'hotel',
        ss:hotel.name
      })
      router.push(localePath('/search?' + query))
    }
  })
}
const fetchRoom = () => {
  loading.value = true;
  useFetch("/api/entity/room", {
    method: "post",
    body: {
      id: props.room?.id,
      locale: locale.value,
    },
  }).then((res) => {
    loading.value = false;
    emit("UpdateRoom", res.data.value);
  });
};
const { sqftToSqm } = useHelper();
const renderRoomsize = (size: number, unit: string) => {
  switch (unit) {
    case "SQUARE_FEET":
      if (locale.value == "en") {
        return `${size} <span class="text-xs">sqft<sup>2</sup></span>`;
      }
      return `${sqftToSqm(size)} <span class="text-xs">m<sup>2</sup></span>`;

    case "SQUARE_METER":
      return `${size} <span class="text-xs">m<sup>2</sup></span>`;
  }
  return `${size} <span class="text-xs">sqft<sup>2</sup></span>`;
};
</script>
