<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useBookingStore } from "~/stores/booking";
import { useHotelStore } from "~/stores/hotel";
import type { Room } from "~/types/Hotel";

const { hotel } = storeToRefs(useHotelStore());
const { makeQueryString } = useBookingStore();
const { redirect, linkBooking } = useBooking();
const { query_string } = storeToRefs(useBookingStore());

const rooms = computed(() => {
  if (hotel.value) {
    return hotel.value.rooms ?? [];
  }
  return [];
});
const open = ref(false);
const room_selected = ref<Room | null>(null);
const selectRoom = (room: Room) => {
  room_selected.value = room;
  open.value = true;
};

const CloseDetailRoom = () => {
  open.value = false;
  room_selected.value = null;
};
const UpdateRoom = (room: Room): any => {
  if (hotel.value && room) {
    for (let i = 0; i < hotel.value.rooms.length; i++) {
      if (hotel.value.rooms[i].id == room.id) {
        hotel.value.rooms[i] = room;
        room_selected.value = room;
        break;
      }
    }
  }
};

const showPrice = () => {
  makeQueryString();
  if (hotel.value && hotel.value.indent_str) {
    var x = linkBooking("/hotel/vn/" + hotel.value.indent_str, query_string.value);
    redirect(x + "#hprt-table");
  }
};
</script>
<template>
  <section class="mt-10 sm:mt-12">
    <V2EntityRoomForm @showPrice="showPrice"></V2EntityRoomForm>
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div
        class="mt-8 flex flex-col-reverse lg:flex-row lg:items-center lg:space-x-4 xl:mt-10"
      >
        <h3
          class="mt-5 grow shrink-0 text-lg font-semibold text-neutral-900 dark:text-white sm:mt-6 md:text-xl lg:mt-0"
        >
          {{ $t("room_options") }}
        </h3>
      </div>
      <div class="mt-5 space-y-2 sm:mt-6 sm:space-y-4">
        <V2EntityRoomItem
          @show-price="showPrice"
          v-for="room in rooms"
          :room="room"
          @selectRoom="selectRoom"
        ></V2EntityRoomItem>
      </div>
      <CoreRoomDialog
        :room="room_selected"
        :open="open"
        @closeModal="CloseDetailRoom"
        @UpdateRoom="UpdateRoom"
      />
    </div>
  </section>
</template>
