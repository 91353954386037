<template>
  <ElementsCardLeft
    :title="$t('facilities_of', { hotel: hotel?.name })"
    :description="hotel?.summary"
    :has_bg="false"
  >
    <div
      class="mt-6 divide-y rounded-3xl border px-4 sm:px-6 bg-white dark:bg-neutral-900"
    >
      <template v-for="amenities_group in hotel?.amenities_group">
        <div v-if="amenities_group.amenities.length">
          <CoreFacilityItem :amenities="amenities_group.amenities">
            <template #icon>
              <Icon
                :name="FindIcon(amenities_group.icon)"
                size="24"
                class="flex-shrink-0 dark:text-neutral-50"
              />
            </template>
            <template #name>
              {{ HtmlDecode(amenities_group.name) }}
            </template>
          </CoreFacilityItem>
        </div>
      </template>
    </div>
  </ElementsCardLeft>
</template>
<script lang="ts" setup>
import { useHotelStore } from "~/stores/hotel";

const { hotel } = useHotelStore();
const HtmlDecode = (text: string) => {
  return text.replaceAll("&#x27;", "'").replaceAll("&amp;", "&");
};
const FindIcon = (icon: any) => {
  if (icon) {
    return icon;
  }
  return "tabler:circle-check";
};
</script>
