<script setup lang="ts">
import { useHotelStore } from "~/stores/hotel";
import type { Room } from "~/types/Hotel";

const props = defineProps({
  room: {
    type: Object as () => Room,
    required: true,
  },
});
const { locale } = useI18n();
const transRoom = computed(() => {
  if (props.room.translation && props.room.translation.length) {
    for (let i = 0; i < props.room?.translation.length; i++) {
      if (props.room?.translation[i].locale == locale.value) {
        return props.room?.translation[i];
      }
    }
  }
  return props.room;
});
const { sqftToSqm } = useHelper();
const {hotel} = useHotelStore()
const renderRoomsize = (size: any, unit: string) => {
  switch (unit) {
    case "SQUARE_FEET":
      if (locale.value == "en") {
        return `${size} <span class="text-xs">sqft<sup>2</sup></span>`;
      }
      return `${sqftToSqm(size)} <span class="text-xs">m<sup>2</sup></span>`;

    case "SQUARE_METER":
      return `${size} <span class="text-xs">m<sup>2</sup></span>`;
  }
  return `${size} <span class="text-xs">sqft<sup>2</sup></span>`;
};
const emits = defineEmits(["selectRoom","showPrice"]);
const selectRoom = (r: Room) => {
  emits("selectRoom", r);
};
const showPrice = ()=>{
  emits('showPrice')
}
</script>
<template>
  <div class="rounded-xl border-2 border-neutral-100">
    <div class="flex flex-col xs:flex-row xs:space-y-0 space-y-3 xs:space-x-3 sm:space-x-4">
      <div
        class="relative w-full h-full shrink-0 self-start aspect-[3/2] xs:aspect-[3/4] xs:w-32 lg:w-72 lg:aspect-[4/3]"
      >
        <div
          v-if="room?.gallery?.length"
          @click="selectRoom(room)"
          class="group absolute inset-x-0 inset-y-0 cursor-pointer overflow-hidden rounded-md rounded-b-none xs:rounded-bl-md md:rounded-r-none bg-image-blank"
        >
          <NuxtImg
            :src="room?.gallery[0]"
            provider="glide"
            width="300"
            :alt="'rom of '+hotel?.name"
            placeholder="/icons/loading_square.svg"
            class="block h-full w-full object-cover transition-all group-focus-visible:scale-110 md:group-hover:scale-110"
          ></NuxtImg>
          <div
            class="absolute bottom-1.5 right-1.5 flex items-center min-w-[20px] rounded-md bg-white/90 px-1 py-0.5 text-center text-sm font-semibold text-neutral-800"
          >
            <span class="hidden md:inline text-xs font-medium" v-if="room.room_type"
              >{{ room.room_type?.name }} - &nbsp;</span
            >
            <Icon
              name="solar:gallery-round-broken"
              size="16"
              class="mr-1"
            ></Icon>
            <span> {{ room?.gallery?.length }}</span>
          </div>
        </div>
        <div
          v-else
          @click="selectRoom(room)"
          class="group absolute inset-x-0 inset-y-0 cursor-pointer overflow-hidden rounded-md rounded-r-none bg-image-blank"
        >
          <NuxtImg
            src="/icons/loading_square.svg"
            width="300"
            class="h-full object-cover"
          ></NuxtImg>
        </div>
      </div>
      <div class="flex grow flex-col sm:flex-row p-2 lg:p-4">
        <div class="flex h-full flex-col sm:grow">
          <div class="grow">
            <div
              @click="selectRoom(room)"
              class="cursor-pointer font-semibold underline hover:underline sm:text-lg sm:no-underline"
            >
              {{ transRoom?.name }}
            </div>
            <div class="mt-3 space-y-2 text-sm text-neutral-700">
              <div
                class="flex items-center space-x-2 text-base text-neutral-700 dark:text-neutral-200"
              >
                <Icon name="bi:people" class="mt-px shrink-0" size="20"></Icon>
                <div class="grow">
                  {{ $t("guests") }} {{ room?.max_guests }}
                </div>
              </div>
              <div class="hidden space-y-2 md:block">
                <div
                  class="flex items-center space-x-2 text-base text-neutral-700 dark:text-neutral-200"
                >
                  <Icon
                    name="solar:crop-minimalistic-broken"
                    size="20"
                    class="mt-px shrink-0"
                  ></Icon
                  >.
                  <div
                    class="grow"
                    v-html="renderRoomsize(room?.room_size, room.unit + '')"
                  ></div>
                </div>
              </div>
              <div class="flex items-start space-x-2 dark:text-neutral-200">
                <Icon
                  name="solar:bed-broken"
                  size="20"
                  class="mt-px shrink-0"
                ></Icon>
                <div>
                  <p class="text-xs lg:text-sm " v-if="transRoom.bedroom">
                    {{ transRoom.bedroom }}
                  </p>
                  <ul v-else class="text-xs lg:text-sm ">
                    <li v-for="apartment in transRoom.apartmentRoom">
                      <div v-for="(value, key) in apartment">
                        <b>{{ key }}: </b>
                        <span>{{ value }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-auto shrink-0 pt-4 sm:pt-2">
            <div class="flex items-center">
              <div class="hidden shrink-0 sm:block">
                <button
                  @click="selectRoom(room)"
                  class="-ml-1 rounded-md p-1 text-sm font-medium text-secondary-600 hover:text-secondary-500 focus:outline-none focus-visible:ring-2"
                >
                  {{ $t("more_details") }}
                </button>
              </div>
              <div class="ml-auto shrink-0">
                <button
                  @click="showPrice"
                  type="button"
                  class="inline-flex select-none items-center justify-center font-semibold focus:outline-none focus-visible:ring-2 focus-visible:ring-secondary-200 bg-secondary-700 text-white hover:bg-secondary-600 focus-visible:bg-secondary-600 active:bg-secondary-600 disabled:bg-secondary-100 text-sm rounded-[10px] px-5 py-3"
                >
                  {{ $t("show_price") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
