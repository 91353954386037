<template>
  <div
    v-if="hotel"
    class="entity-ads block text-center mx-auto overflow-hidden rounded-xl hover:drop-shadow-2xl drop-shadow-md transition-all ease-in-out duration-150 h-40 my-6 sm:my-12"
  >
    <div
      class="text-block relative flex items-center justify-center bg-gradient-to-bl from-sky-400 to-blue-800 text-white"
    >
      <div class="flex flex-col justify-center items-center px-4 sm:px-8">
        <p class="md:text-lg text-base font-semibold mb-4">{{ cta }}</p>

        <button @click="openLink" class="mt-4">
          <NuxtImg
            src="/images/booking.com.png"
            class="md:h-8 h-6"
            alt="redirect to booking.com"
          />
        </button>
      </div>
    </div>
    <div class="image-block group overflow-hidden">
      <img
        :src="hotel.thumbnail_url"
        width="400"
        class="h-full min-w-full object-cover"
        :alt="hotel.name + ' - thumbnail'"
      />
    </div>
  </div>
</template>
<script setup>
import { useHotelStore } from "~/stores/hotel";
const { hotel } = useHotelStore();
const { linkBooking, redirect } = useBooking();
const booking_link = computed(() => {
  if (hotel) {
    return linkBooking("/hotel/vn/" + hotel.indent_str, {}, "AV Ads");
  }
  return linkBooking(
    "/searchresults",
    {
      ss: "Vietnam",
    },
    "AV Ads"
  );
});
const openLink = () => {
  redirect(booking_link.value);
};
const hotelCTAs = {
  en: [
    "Book Now, Rest Easy!",
    "Exclusive Hotel Deals Await!",
    "Secure Your Stay Today!",
    "Last Room Alert! Book Now.",
    "Luxury Rooms, One Click Away.",
  ],
  vi: [
    "Đặt ngay, nghỉ ngơi thoải mái!",
    "Ưu đãi độc quyền từ khách sạn đang chờ bạn!",
    "Đảm bảo chỗ ở của bạn hôm nay!",
    "Có thể chỉ còn phòng cuối cùng!",
    "Phòng sang trọng, chỉ cần một cú nhấp.",
  ],
};

const resortCTAs = {
  en: [
    "Your Dream Escape Awaits!",
    "Dive into Paradise. Book Now!",
    "Reserve Your Ultimate Getaway!",
    "Sun, Sand, and Serenity. Book Today!",
    "Find Your Oasis. Secure Your Spot Now.",
  ],
  vi: [
    "Chốn thiên đường trong mơ đang chờ bạn!",
    "Lặn vào thiên đường. Đặt phòng ngay!",
    "Đặt chỗ cho kỳ nghỉ hoàn hảo của bạn!",
    "Nắng, cát và sự yên bình. Đặt phòng hôm nay!",
    "Tìm kiếm thiên đường riêng của bạn. Đảm bảo chỗ ngay!",
  ],
};
const cta = ref("Unlock Your Perfect Stay. Book Now!");
const { locale } = useI18n();
function getRandomItem(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}
onMounted(() => {
  if (useRoute().params.type == "hotels") {
    cta.value = getRandomItem(hotelCTAs[locale.value]);
  } else {
    cta.value = getRandomItem(resortCTAs[locale.value]);
  }
});
</script>
<style lang="scss" scoped>
.entity-ads {
  display: flex;

  .text-block,
  .image-block {
    flex: 0 0 50%;
    width: 50%;
  }

  .text-block {
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 28px;
      right: -28px;
      transform: skewX(-10deg);
      z-index: 0;
      @apply bg-sky-500/50;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 28px;
      @apply bg-blue-800;
      right: -14px;
      transform: skewX(-10deg);
      z-index: 0;
    }
  }
}

@media (max-width: 768px) {
  .entity-ads {
    .text-block {
      flex: 0 0 70%;
      width: 70%;
    }

    .image-block {
      flex: 0 0 30%;
      width: 30%;
    }
  }
}
@media (max-width: 480px) {
  .entity-ads {
    .text-block {
      flex: 0 0 100%;
      width: 100%;
    }

    .image-block {
      flex: 0 0 0%;
      width: 0%;
    }
  }
}
</style>
