<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useCurrencyStore } from "~/stores/currency";
import { useHotelStore } from "~/stores/hotel";

const { hotel } = storeToRefs(useHotelStore());
const shareModal = ref(false);
const { currency, currency_code } = storeToRefs(useCurrencyStore());
const { actionExchange } = useCurrencyStore();

const { scrollContent } = useLenisClient();

const pending = ref(true);

const { query } = useRoute();
const { locale } = useI18n();
const { object2Query } = useHelper();
const fetchPrice = async () => {
  try {
    pending.value = true;
    var price = await $fetch(
      "/api/graph-pricing?" +
        object2Query({
          ...query,
          currency: currency.value,
          locale: locale.value,
        })
    );
    if (hotel.value) {
      hotel.value.price = price as any;
    }
  } catch (error) {}
  pending.value = false;
};

if (query.checkin && query.checkout && query.dest_id) {
  fetchPrice();
}
watch(currency, () => {
  fetchPrice();
});
const heading = computed(()=>{
  if (hotel.value?.title) {
    return hotel.value.title
  }
  return hotel.value?.name
})
</script>
<template>
  <div class="mt-5">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex md:items-center md:flex-row flex-col md:space-x-4">
        <div class="grid grow grid-cols-1 gap-y-2">
          <div class="flex w-full space-x-4">
            <h1 class="grow text-xl font-bold sm:text-2xl lg:grow-0">
              {{ heading }}
            </h1>
            <div class="hidden md:inline-block shrink-0">
              <button
                class="relative rounded-full flex items-center justify-center h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
                :title="$t('share')"
                @click="shareModal = true"
              >
                <IconShare class="w-5 h-5" />
                <span class="sr-only">{{ $t("share") }}</span>
              </button>
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="flex items-center space-x-4">
              <span v-if="hotel?.star" class="flex items-center space-x-1">
                <svg
                  v-for="i in hotel.star"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-3 fill-yellow-400"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23.555 8.729a1.505 1.505 0 0 0-1.406-.98h-6.087a.5.5 0 0 1-.472-.334l-2.185-6.193a1.5 1.5 0 0 0-2.81 0l-.005.016-2.18 6.177a.5.5 0 0 1-.471.334H1.85A1.5 1.5 0 0 0 .887 10.4l5.184 4.3a.5.5 0 0 1 .155.543l-2.178 6.531a1.5 1.5 0 0 0 2.31 1.684l5.346-3.92a.5.5 0 0 1 .591 0l5.344 3.919a1.5 1.5 0 0 0 2.312-1.683l-2.178-6.535a.5.5 0 0 1 .155-.543l5.194-4.306a1.5 1.5 0 0 0 .433-1.661"
                  ></path>
                </svg>
              </span>
              <span
                class="shrink-0 rounded-md capitalize bg-secondary-100 px-3 py-1 text-xs text-secondary-700"
                >{{ hotel?.hotel_type }}</span
              >
              <button
                class="relative rounded-full flex md:hidden items-center justify-center h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
                :title="$t('share')"
                @click="shareModal = true"
              >
                <IconShare class="w-5 h-5" />
                <span class="sr-only">{{ $t("share") }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="shrink-0 mt-4 md:mt-0">
          <div class="flex flex-row space-x-4">
            <template v-if="query.checkin && query.checkout">
              <template v-if="pending">
                <div class="flex flex-col items-end justify-center space-y-2">
                  <div
                    class="h-3 animate-pulse rounded-lg bg-neutral-100 sm:w-10"
                  ></div>
                  <div
                    class="h-6 animate-pulse rounded-lg bg-neutral-100 sm:w-28"
                  ></div>
                </div>
                <div
                  class="h-[52px] animate-pulse rounded-lg bg-neutral-100 sm:w-32"
                ></div>
              </template>
              <div v-else class="shrink-0 text-right">
                <ElementsPrice
                  :display-price="hotel?.price?.displayPrice"
                  :price-before-discount="hotel?.price?.priceBeforeDiscount"
                ></ElementsPrice>
                <div class="whitespace-nowrap text-xs text-secondary-800 dark:text-secondary-400">
                  {{ hotel?.price?.nights }} {{ $t("nights") }},
                  {{ hotel?.price?.room }} {{ $t("rooms") }}
                </div>
              </div>
            </template>
            <template v-else-if="hotel?.from_price">
              <div class="shrink-0 flex-center text-right">
                <div class="whitespace-nowrap text-xl font-semibold">
                  <span class="text-sm">{{ $t("from") }} </span>
                  {{ currency_code
                  }}{{ actionExchange(hotel?.from_price + "") }}
                </div>
              </div>
              <button
                v-if="true"
                type="button"
                @click="scrollContent('rooms')"
                class="whitespace-nowrap rounded-xl bg-secondary-50 px-6 py-3 text-sm font-bold text-secondary-800 hover:bg-secondary-100 focus:outline-none focus-visible:ring-2 active:bg-secondary-100"
              >
                {{ $t("show_price") }}
              </button>
              <button
                v-else
                type="button"
                class="inline-flex select-none items-center justify-center font-semibold focus:outline-none focus-visible:ring-2 focus-visible:ring-secondary-200 bg-secondary-600 text-white hover:bg-secondary-500 focus-visible:bg-secondary-500 active:bg-secondary-600 disabled:bg-secondary-100 text-base rounded-xl px-4 py-2"
              >
                {{ $t("show_deal") }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <CoreShareDialog
      :open="shareModal"
      @closeModal="() => (shareModal = false)"
    ></CoreShareDialog>
  </div>
</template>
