<script lang="ts" setup>
import { useHotelStore } from "~/stores/hotel";
const { hotel } = useHotelStore();
const amenities = computed(() => {
  var ams: string | any[] = [];
  if (hotel && hotel.highlight_facilities) {
    ams = hotel.highlight_facilities;
  }
  if (!ams.length && hotel?.amenities_group?.length) {
    ams = hotel?.amenities_group[0].amenities;
  }
  return ams;
});
const {scrollContent} = useLenisClient()
</script>
<template>
  <div
    class="flex h-full flex-col items-start p-3 rounded-2xl border border-neutral-200 lg:p-4"
  >
    <div class="md:h-12 md:overflow-hidden lg:h-14">
      <div
        class="-ml-4 -mt-2 flex flex-wrap text-neutral-800 dark:text-white lg:-ml-5 lg:-mt-4"
      >
        <span
          v-for="amenity in amenities"
          class="inline-flex items-center pl-4 pt-2 lg:pl-5 lg:pt-4"
        >
          <Icon
            name="material-symbols:check-rounded"
            class="mr-2 shrink-0"
            size="16"
          ></Icon>
          <span class="whitespace-nowrap text-sm"> {{ amenity.name }}</span>
        </span>
      </div>
    </div>
    <div class="mt-auto pt-2 lg:pt-4 hidden lg:block">
      <button
        @click="scrollContent('facility')"
        class="text-sm font-medium text-secondary-700 dark:text-secondary-400 hover:text-secondary-600 focus:outline-none focus-visible:text-secondary-600 focus-visible:ring-2"
      >
        {{ $t("see_details") }}
      </button>
    </div>
  </div>
</template>
