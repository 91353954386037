<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="relative grid grid-cols-3 sm:grid-cols-4 gap-2">
      <div
        @click="ClickImage"
        class="col-span-2 row-span-3 sm:row-span-2 relative rounded-xl overflow-hidden cursor-pointer z-0"
        :class="{
          'col-span-2': gallery.length > 3,
          'sm:col-span-3 col-span-2': gallery.length <= 3 && gallery.length > 1,
          'sm:col-span-4 col-span-3': gallery.length == 1,
        }"
      >
        <div class="absolute inset-0">
          <NuxtImg
            :src="gallery[0]"
            provider="glide"
            sizes="400px md:800px"
            quality="80"
            densities="x1"
            fetchpriority="high"
            format="webp"
            loading="lazy"
            placeholder="/icons/loading.svg"
            class="object-cover w-full h-full rounded-xl absolute inset-0"
            :alt="hotel?.slug"
            :title="hotel?.name"
          />
        </div>
        <ElementsOverlay />
      </div>
      <template v-if="gallery.length > 1">
        <div
          @click="ClickImage"
          v-for="(image, i) in gallery.slice(1, 5)"
          class="relative rounded-xl overflow-hidden z-0"
          :class="{ 'hidden sm:block': i >= 3 }"
        >
          <div class="aspect-w-6 aspect-h-4">
            <NuxtImg
              :src="image"
              provider="glide"
              sizes="200px md:400px"
              densities="x1"
              quality="80"
              format="webp"
              loading="lazy"
              placeholder="/icons/loading.svg"
              :alt="hotel?.slug"
              :title="hotel?.name"
              class="object-cover w-full h-full rounded-xl absolute inset-0"
            />
          </div>
          <template v-if="i == 2">
            <div
              @click="OpenMasonry"
              class="sm:hidden absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 z-20 flex items-center justify-center"
            >
              <span class="font-medium text-white text-sm">
                +{{ gallery.length }} Photos
              </span>
            </div>
          </template>
          <ElementsOverlay />
        </div>
      </template>
      <button
        @click="OpenMasonry"
        v-if="gallery.length - 4 > 0"
        class="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-full bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
      >
        <IconFourSquare class="h-5 w-5 lg:inline hidden" />
        <span class="ml-2 text-neutral-800 text-sm font-medium">
          {{ $t("show_all_photos") }}
          <span class="text-xs lg:inline hidden"
            >(+{{ gallery.length - 4 }})</span
          >
        </span>
      </button>
    </div>
    <LazyGeneralMasonryModal :alt="hotel?.name" :gallery="gallery" />
    <LazyGeneralLightboxModal :alt="hotel?.name" :gallery="gallery" />
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useConfigStore } from "~/stores/config";
import { useHotelStore } from "~/stores/hotel";

const { hotel } = useHotelStore();
const { masonry_modal } = storeToRefs(useConfigStore());

const gallery = computed(() => {
  var photos = hotel?.gallery || [];
  var roomsPhotos: string[] = [];
  if (hotel && hotel.rooms.length) {
    hotel.rooms.forEach((room) => {
      if (room.gallery?.length) {
        roomsPhotos = [...roomsPhotos, ...room.gallery];
      }
    });
  }
  const items = [...photos, ...roomsPhotos];
  return items.slice(0, 54);
});
const { isMobileOrTablet } = useDevice();
const OpenMasonry = () => {
  masonry_modal.value = true;
  // parent.location.hash = 'open-masonry'
};
const ClickImage = () => {
  if (!isMobileOrTablet) {
    OpenMasonry();
  }
};
</script>
