<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useBookingStore } from "~/stores/booking";
import { useHotelStore } from "~/stores/hotel";

const { hotel } = useHotelStore();
const place = computed(() => {
  return hotel?.place;
});

const { form } = storeToRefs(useBookingStore());
const { object2Query } = useHelper();
const router = useRouter();
const localePath = useLocalePath();
const href = computed(() => {
  if (place.value) {
    var query:any = {
      ss: place.value.name,
      checkin: form.value.datetime[0],
      checkout: form.value.datetime[1],
      group_adults:form.value.adults,
      no_rooms:form.value.rooms,
      group_children:form.value.children
    };
    if (place.value.dest_id) {
      query.dest_id = place.value.dest_id;
      query.dest_type = place.value.dest_type;
    }
    return localePath("/search") + "?" + object2Query(query);
  }
  return localePath("/");
});
</script>
<template>
  <div class="container mx-auto mt-3 px-4 sm:mt-4 sm:px-6 lg:px-8" v-if="place">
    <NuxtLink
      :to="href"
      class="-ml-1 flex w-fit items-center space-x-1 text-sm text-secondary-500 hover:text-secondary-400 focus:outline-none focus-visible:ring-2"
    >
      <Icon
        name="solar:alt-arrow-left-outline"
        class="shrink-0"
        size="16"
      ></Icon>
      <span class="line-clamp-1">{{ $t("see_all") }} {{ place.name }}</span>
    </NuxtLink>
  </div>
</template>
