<template>
  <ElementsCardLeft :title="$t('guest_reviews')" :has_bg="true">
    <CoreReviewsCard />
    <div class="flex-center mt-8">
      <button
        @click="openLink()"
        class="md:px-4 px-3 py-2 bg-secondary-600 text-white rounded-lg drop-shadow-sm border border-neutral-200 text-sm flex items-center md:text-base dark:bg-neutral-900"
      >
        {{ $t("view_all_review") }}
      </button>
    </div>
  </ElementsCardLeft>
</template>
<script lang="ts" setup>
import { useHotelStore } from "~/stores/hotel";

const { hotel } = useHotelStore();
const { linkBooking,redirect } = useBooking();
const openLink = () => {
  const url = "/reviews/vn/hotel/" + hotel?.indent_str;
  return redirect(linkBooking(url));
};
</script>
