<template>
  <div class="w-full pb-1 pt-4 sm:pb-5 sm:pt-8 lg:flex lg:items-start lg:space-x-5 lg:pt-5">
    <div
      class="flex shrink-0 items-start space-x-3 text-base sm:text-lg text-neutral-700 dark:text-white lg:w-1/3 lg:pt-3"
    >
      <div class="mt-0.5 shrink-0">
        <slot name="icon" />
      </div>
      <h3 class="font-semibold">
        <slot name="name" />
      </h3>
    </div>
    <div
      class="-ml-5 mb-1.5 mt-1.5 flex flex-wrap text-neutral-800 dark:text-neutral-200 sm:mt-3.5 lg:mt-1.5"
    >
      <transition-group
        enter-from-class="-translate-x-full"
        enter-to-class=""
        leave-active-class=""
        leave-from-class=""
        leave-to-class="-translate-x-full opacity-0"
      >
        <div
          v-for="amenity in amenities.slice(0, limit)"
          class="my-1.5 ml-5 flex items-center space-x-2"
        >
          <Icon
            name="material-symbols:check-rounded"
            class="mr-1"
            size="16"
          ></Icon>
          <span class="text-sm sm:text-base">{{ HtmlDecode(amenity.name) }}</span>
        </div>
      </transition-group>
      <div class="w-full">
        <button
          v-if="amenities.length > 10"
          @click="expand = !expand"
          class="my-1.5 ml-5 flex items-center space-x-2 border px-2 py-1 text-sm bg-gray-200 border-neutral-200 dark:bg-neutral-900 rounded-md"
        >
          <Icon
            name="tabler:plus"
            class="flex-shrink-0"
            v-if="!expand"
            size="16"
          />
          <Icon
            name="material-symbols:expand-less"
            v-else
            class="flex-shrink-0"
            size="16"
          />
          <span v-if="!expand"
            >{{ $t("view_facilities") }} ({{ amenities.length - 10 }})</span
          >
          <span v-else>
            {{ $t("show_less") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  amenities: {
    type: Array as () => any[],
    default: [],
  },
});
const expand = ref(false);
const limit = computed(() => {
  return expand.value ? props.amenities.length : 10;
});
const HtmlDecode = (text: string) => {
  return text.replaceAll("&#x27;", "'").replaceAll("&amp;", "&");
};
</script>
