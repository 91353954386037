<script lang="ts" setup>
import { useHotelStore } from "~/stores/hotel";
const { hotel } = useHotelStore();
const localePath = useLocalePath()
const { scrollContent } = useLenisClient();

</script>
<template>
  <div
    class="relative h-full overflow-hidden p-3 rounded-2xl border border-neutral-100 lg:p-4"
  >
    <img
      src="/images/map.png"
      class="absolute right-0 top-0 h-full w-1/2 object-cover"
      alt="map"
    />
    <div
      class="absolute left-1/2 top-0 h-full w-1/2 bg-gradient-to-r from-white to-white/0 dark:from-neutral-900/95 dark:to-neutral-900/0"
    ></div>
    <div class="relative flex h-full flex-col items-start">
      <div class="flex w-full items-center space-x-2 text-neutral-800 dark:text-white">
        <Icon
          name="solar:map-point-wave-linear"
          size="20"
          class="flex-shrink-0"
        />
        <span class="grow line-clamp-1 text-sm">{{ hotel?.address }}</span>
      </div>
      <div
        class="mt-2 flex w-full items-center space-x-2 text-neutral-800 dark:text-white lg:mt-3"
      >
        <Icon
          name="maki:landmark"
          class="shrink-0"
          size="24"
        ></Icon
        ><NuxtLink :to="localePath('/place/'+hotel?.place?.slug)" class="grow truncate text-sm">{{ $t('attractions_in') }} {{ hotel?.place?.name }}</NuxtLink>
      </div>
      <div class="mt-auto pt-2 lg:pt-4 hidden lg:block">
        <button
        @click="scrollContent('location')"
          class="text-sm font-medium text-secondary-700 dark:text-secondary-400 hover:text-secondary-600 focus:outline-none focus-visible:text-secondary-600 focus-visible:ring-2"
        >
          {{$t('view_on_map')}}
        </button>
      </div>
    </div>
  </div>
</template>
