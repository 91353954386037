<template>
  <div
    class="relative py-8 sm:py-12 mt-8 px-0 sm:px-4"
    v-if="faqs && faqs.length"
  >
    <div
      class="relative flex flex-col sm:flex-row sm:items-end justify-between mb-10 md:mb-12 text-neutral-900 dark:text-neutral-50"
    >
      <h2
        class="text-xl md:text-2xl lg:text-4xl font-semibold md:leading-[36px] lg:leading-[46px]"
      >
        {{ $t("faq_about", { hotel: hotel?.name }) }}
      </h2>
    </div>
    <div
      class="divide-y rounded-3xl border px-4 sm:px-6 bg-white dark:bg-neutral-900"
    >
      <div class="mt-4 grid gap-x-8 gap-y-10 xl:grid-cols-2">
        <div  v-for="faq in faqs">
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between dark:hover:bg-neutral-800 pr-4 py-4 text-left text-base font-semibold border-b border-neutral-200"
              :class="{ 'dark:bg-neutral-800': open }"
            >
              <h3>{{ faq.question }}</h3>
              <Icon
                name="solar:alt-arrow-down-outline"
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-success"
              />
            </DisclosureButton>
            <DisclosurePanel
              class="px-4 pt-4 pb-2 text-sm leading-6 dark:bg-neutral-800"
            >
              <div
                class="prose dark:prose-invert dark:text-white max-w-full"
                v-html="fixHtmlTags(faq.answer)"
              ></div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { useHotelStore } from "~/stores/hotel";

const { hotel } = useHotelStore();
const { locale } = useI18n();

const faqs = computed(() => {
  if (hotel && hotel.faq) {
    const f = hotel.faq.find((item) => item.locale == locale.value);
    return f?.questions ?? [];
  }
  return [];
});
const fixHtmlTags = (htmlString: string) => {
  return htmlString
    .replace(/<\s*\/\s*ul>/g, "</ul>")
    .replace(/<\s*li>/g, "<li>")
    .replace(/<\s*\/\s*li>/g, "</li>")
    .replace(/<\s*p>/g, "<p>")
    .replace("</ p>", "</p>")
    .replace("<  /p>", "</p>");
};
</script>
