export function useLenisClient() {
  const lenis = useLenis();
  const scrollContent = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - 50;
      lenis.instance.value.scrollTo(offsetTop);
    }
  };
  return {
    scrollContent,
  };
}
