<template>
  <div class="relative">
    <div class="embla relative" ref="emblaNode">
      <div class="embla__container">
        <template v-if="gallery.length">
          <div class="embla__slide" v-for="img in gallery">
            <NuxtImg
              class="embla__slide__img"
              :src="img"
              provider="storage"
              :alt="alt"
              loading="lazy"
              placeholder="/icons/loading.svg"
            />
          </div>
        </template>
        <template v-else>
          <div class="embla__slide">
            <img
              class="embla__slide__img"
              :src="'/icons/loading.svg'"
              :alt="'default-image'"
            />
          </div>
        </template>
      </div>
      <div class="absolute bottom-3 right-3 text-neutral-800">
        <div
          class="inline-flex items-center space-x-1 rounded-lg bg-white/90 px-2 py-1"
        >
          <Icon name="solar:gallery-round-broken" size="16"></Icon>
          <div class="grow truncate text-sm font-semibold">
            {{ slideIndexActive + 1 }}/{{ gallery.length }}
          </div>
        </div>
      </div>
      <ElementsArrowSlider
        :show-next="showNext"
        :show-prev="showPrev"
        @prevSlide="prevSlide"
        @nextSlide="nextSlide"
        :size="'small'"
      ></ElementsArrowSlider>
    </div>
    <div class="embla-thumbs" ref="emblaThumbs">
      <div class="embla-thumbs__container">
        <template v-if="gallery.length">
          <div class="embla-thumbs__slide" v-for="(img, index) in gallery">
            <button
              class="embla-thumbs__slide__button h-16 rounded-lg overflow-hidden"
              :class="{
                'active ring ring-secondary-600': slideIndexActive == index,
              }"
              @click="setEmblaIndex(index)"
              type="button"
            >
              <NuxtImg
                class="object-cover w-full h-full duration-100 ease-in-out transition-all"
                :class="{ ' scale-105': slideIndexActive == index }"
                :src="img"
                provider="glide"
                width="100"
                height="100"
                format="webp"
                alt="Your alt text"
                loading="lazy"
                placeholder="/icons/loading.svg"
              />
            </button>
          </div>
        </template>
        <template v-else> </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import EmblaCarousel from "embla-carousel-vue";

const props = defineProps({
  gallery: {
    type: Array as () => string[],
    default: [],
  },
  alt: {
    type: String,
    default: "",
  },
});
const [emblaNode, emblaApi] = EmblaCarousel({});
const [emblaThumbs, emblaApiThumb] = EmblaCarousel({
  loop: false,
  dragFree: true,
  align: "start",
});

const nextSlide = () => {
  emblaApi.value?.scrollNext();
};
const prevSlide = () => {
  emblaApi.value?.scrollPrev();
};
const slideIndexActive = ref(0);
const showPrev = ref(false);
const showNext = ref(true);
const slideChanged = () => {
  if (emblaApi.value) {
    if (
      emblaApi.value.slidesInView().includes(props.gallery.length - 1) ||
      emblaApi.value.slidesInView().length == 0
    ) {
      showNext.value = false;
    } else {
      showNext.value = true;
    }
    if (emblaApi.value.slidesInView().includes(0)) {
      showPrev.value = false;
    } else {
      showPrev.value = true;
    }

    var index = emblaApi.value.slidesInView().length;
    slideIndexActive.value = emblaApi.value.slidesInView()[index - 1];
  }
};
watchEffect(() => {
  if (!emblaApi.value) return;
  emblaApi.value.on("slidesInView", slideChanged);
});

watch(slideIndexActive, () => {
  emblaApiThumb.value?.scrollTo(slideIndexActive.value);
});
const setEmblaIndex = (index: number) => {
  if (index != slideIndexActive.value) {
    slideIndexActive.value = index;
    emblaApi.value?.scrollTo(index);
  }
};
</script>
<style scoped>
.embla {
  --slide-spacing: 0rem;
  --slide-size: 100%;
  --slide-height: 28rem;
  padding: 0;
  overflow: hidden;
  border-radius: 12px 0 0 0px;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
  display: flex;
  justify-content: center;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}

.embla-thumbs {
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 12rem;
  padding: 15px;
  overflow: hidden;
}
.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
}
.embla-thumbs__slide {
  flex: 0 0 28%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
  position: relative;
}
@media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 20%;
  }
}
.embla-thumbs__slide__button.active {
  opacity: 1;
}
.embla-thumbs__slide__button {
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .embla {
    border-radius: 0 0 0 0px;
  }
}
@media (max-width: 480px) {
  .embla__slide__img {
    height: 16rem;
  }
}
</style>
