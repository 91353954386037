<template>
  <div class="py-8">
    <V2EntityPlaceBreadcrumb></V2EntityPlaceBreadcrumb>
    <V2EntityInfo></V2EntityInfo>
    <section class="mt-8">
      <CoreGallery />
    </section>
    <section class="mt-4">
      <CoreNavigation />
    </section>
    <section id="overview" class="mt-8">
      <V2EntityBadge></V2EntityBadge>
    </section>
  </div>
  <V2EntityRoom id="rooms"></V2EntityRoom>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <CoreContent id="content"></CoreContent>
    <CoreNearby id="location" />
    <CoreFacility id="facility" />
    <LazyCoreHouserule id="house_rules" />
    <CoreReview id="reviews" />
    <CoreFaq id="faq" />
    <!-- <LazyCoreHotelComment /> -->
    <LazyCoreRelatedAttraction id="related_attractions" />
    <LazyCoreRelatedHotel id="related_hotels" />
  </div>
  <CoreBreadrumb></CoreBreadrumb>
  <V2EntityStickyButton></V2EntityStickyButton>
</template>
<script lang="ts" setup>
import { type Hotel } from "~/types/Hotel";
import { useHotelStore } from "~/stores/hotel";
import { storeToRefs } from "pinia";
definePageMeta({
  middleware: ["validate-type"],
});
const error = useError();
const { slug, type } = useRoute().params;
const store = useHotelStore();
const { hotel } = storeToRefs(store);
const { locale, t } = useI18n();

const { data } = await useAsyncData(async () => {
  try {
    var res: Hotel = await $fetch("/api/entity", {
      body: JSON.stringify({
        slug,
        type,
        locale: locale.value,
      }),
      method: "POST",
    });
    hotel.value = res;
    return res;
  } catch (e) {
    error.value = { statusCode: 404, message: "Page not found", name: "Error" };
    return null;
  }
});

const meta = useMeta();

const i18nHead = useLocaleHead({
  addSeoAttributes: {
    canonicalQueries: ["/"],
  },
});
const meta_tags = meta.metaTag({
  title: hotel.value?.title || hotel.value?.name,
  description: hotel.value?.description,
  url: meta.currentURL(),
});
const dayjs = useDayjs();
useHead({
  title: hotel.value?.title,
  meta: [
    { name: "description", content: hotel.value?.description },
    {
      name: "keywords",
      content: hotel.value?.keywords,
    },
    ...(i18nHead.value.meta || []),
    ...meta_tags,
  ],
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
});

const commentsDefineMeta = computed(() => {
  if (hotel.value && hotel.value.comments?.length) {
    var jsondata = [];
    for (let i = 0; i < hotel.value.comments.length; i++) {
      const c = hotel.value.comments[i];
      jsondata.push(
        defineComment({
          text: c.content,
          author: {
            "@type": "Person",
            name: c.user?.full_name,
            image: c.user?.avatar_url,
          },
        })
      );
    }
    return jsondata.map((item) => {
      delete item._resolver;
      return item;
    });
  }

  return [];
});

const defineAmenties = () => {
  var d = [];
  if (hotel.value && hotel.value.amenities_group) {
    for (let i = 0; i < hotel.value.amenities_group.length; i++) {
      for (
        let j = 0;
        j < hotel.value.amenities_group[i].amenities.length;
        j++
      ) {
        const amen = hotel.value.amenities_group[i].amenities[j];
        d.push({
          "@type": "LocationFeatureSpecification",
          name: amen.name,
        });
      }
    }
  }
  return d;
};
const reviewsMetaJd = () => {
  var d = [];
  if (hotel.value && hotel.value.user_ratings) {
    for (let i = 0; i < hotel.value.user_ratings.length; i++) {
      const rate = hotel.value.user_ratings[i];
      d.push({
        "@type": "Review",
        author: {
          "@type": "Person",
          name: rate.author_name,
          image:
            rate.profile_photo_url ??
            "https://cdn.attractionsvietnam.com/tiptap/images/65a4ddb96c1ae.jpg",
        },
        datePublished: dayjs(rate.time * 1000).toISOString(),
        description: rate.text,
        reviewRating: {
          "@type": "Rating",
          ratingValue: rate.rating,
          bestRating: rate.provider == "booking" ? "10" : "5",
        },
      });
    }
  }
  return d;
};
const route = useRoute();
const aggregateRating = computed(() => {
  if (hotel.value) {
    var rate = 0;
    var rate_count = 0;
    var best_rating = 5;
    if (hotel.value.rating_google) {
      rate = hotel.value.rating_google;
      rate_count = hotel.value.google_reviews_count ?? 100;
    }
    if (hotel.value?.rating_booking) {
      rate = hotel.value.rating_booking;
      rate_count = hotel.value.booking_reviews_count ?? 100;
      best_rating = 10;
    }
    return {
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: parseFloat(rate + ""),
        reviewCount: parseInt(rate_count + ""),
        bestRating: best_rating,
      },
    };
  }
  return {};
});
const addressObject = computed(() => {
  if (hotel.value) {
    return {
      addressCountry: hotel.value.country,
      postalCode: hotel.value.postal_code,
      streetAddress: hotel.value.street_address,
    };
  }
  return {};
});
const faqs = computed(() => {
  var faqs: {
    answer: string;
    question: string;
  }[] = [];
  if (hotel.value?.faq) {
    const f = hotel.value?.faq.find((item) => item.locale == locale.value);
    //@ts-ignore
    faqs = f?.questions || [];
  }
  var d = [];
  for (let i = 0; i < faqs.length; i++) {
    d.push(
      defineQuestion({
        name: faqs[i].question,
        acceptedAnswer: faqs[i].answer
          .replace(/\n/gi, "")
          .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/gi, "")
          .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/gi, "")
          .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/gi, "")
          .replace(/<\/\s*(?:p|div)>/gi, "\n")
          .replace(/<br[^>]*\/?>/gi, "\n")
          .replace(/<[^>]*>/gi, "")
          .replace("&nbsp;", " ")
          .replace(/[^\S\r\n][^\S\r\n]+/gi, " "),
      })
    );
  }
  return d;
});
useSchemaOrg([
  defineWebPage({
    name: hotel.value?.name,
    datePublished: dayjs(hotel.value?.created_at).toISOString(),
    dateModified: dayjs(hotel.value?.updated_at).toISOString(),
    description: hotel.value?.description,
    inLanguage: meta.inLanguages(true),
    url: meta.currentURL(),
  }),
  // ...defineAmenties(),
  defineBreadcrumb({
    itemListElement: [
      {
        item: {
          name: t(hotel.value?.hotel_type + ""),
          "@id": meta.currentHost() + "/" + route.params.type,
        },
      },
      {
        item: {
          name: hotel.value?.name,
          "@id": meta.currentURL(),
        },
      },
    ],
  }),
  defineLocalBusiness({
    "@type": "Hotel",
    name: hotel.value?.name,
    telephone: hotel.value?.phone,
    url: meta.currentURL(),
    image: hotel.value?.thumbnail_url,
    numberOfRooms: hotel.value?.rooms.length || 1,
    checkinTime: "T14:00",
    checkoutTime: "T12:00",
    priceRange: {
      "@type": "priceRange",
      minPrice: hotel.value?.from_price,
    },
    address: addressObject.value,
    ...aggregateRating.value,
    review: reviewsMetaJd(),
    amenityFeature: defineAmenties(),
    geo: {
      "@type": "GeoCoordinates",
      latitude: hotel.value?.lat,
      longitude: hotel.value?.lng,
    },
  }),
  ...faqs.value,
]);

const rating = computed(() => {
  if (hotel.value) {
    return hotel.value.rating_booking;
  }
  return 8;
});
const runtimeConfig = useRuntimeConfig();
defineOgImage({
  component: "Entity",
  title: hotel.value?.name,
  isEntity: true,
  star: hotel.value?.star,
  image: hotel.value?.thumbnail_url,
  rating: rating.value,
  gallery: hotel.value?.gallery
    .filter((item) => !item.includes("webp"))
    .map((item) => runtimeConfig.public.storage_url + "/" + item)
    .slice(0, 4),
});
</script>
