<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useBookingStore } from '~/stores/booking';
import { useHotelStore } from '~/stores/hotel';

const { hotel } = storeToRefs(useHotelStore());
const { makeQueryString } = useBookingStore();
const { redirect, linkBooking } = useBooking();
const { query_string } = storeToRefs(useBookingStore());

const openLink = ()=>{
  makeQueryString();
  if (hotel.value && hotel.value.indent_str) {
    var x = linkBooking("/hotel/vn/" + hotel.value.indent_str, query_string.value);
    redirect(x + "#hprt-table");
  }
}
</script>
<template>
  <div
    class="sticky bottom-0 z-40 justify-center flex lg:py-8 py-4 w-fit mx-auto"
  >
    <div
      class="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1 flex items-center justify-center space-x-2 text-xs"
    >
      <button
      @click="openLink"
        class="w-full flex items-center justify-center lg:h-12 h-10 rounded-full px-3 sm:px-4 lg:px-8 text-white bg-gradient-to-bl from-secondary-600 to-teal-600 text-sm lg:text-base font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          class="icon sm:mr-2 mr-1 tiny:block hidden"
          style=""
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          data-v-4fa0a2a1=""
        >
          <path
            fill="currentColor"
            fill-rule="evenodd"
            d="M5 11a6 6 0 1 1 12 0a6 6 0 0 1-12 0m6-8a8 8 0 1 0 4.906 14.32l3.387 3.387a1 1 0 0 0 1.414-1.414l-3.387-3.387A8 8 0 0 0 11 3m0 12a4 4 0 1 0 0-8a4 4 0 0 0 0 8"
            clip-rule="evenodd"
          ></path></svg
        ><span> 
            {{ $t('show_price') }}
        </span>
      </button>
    </div>
  </div>
</template>
