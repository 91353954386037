<template>
  <ElementsCardLeft :title="$t('location_nearby')" :has_bg="false">
    <template v-if="hotel?.lat && hotel.lng" #link_button>
      <button
        @click="openMap"
        class="relative w-fit h-12 drop-shadow-sm hover:drop-shadow-md sm:ml-auto flex-shrink-0 flex items-center justify-center rounded-full transition-colors border-transparent bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 text-sm sm:text-base font-medium px-6 mt-8 md:mt-0"
      >
        <Icon name="logos:google-maps" class="mr-1" size="24" />
        Google map
      </button>
    </template>
    <div class="grid grid-cols-3 gap-5">
      <div class="col-span-3 lg:col-span-2">
        <div class="pb-3 leading-none lg:hidden">
          <div class="inline-flex items-start space-x-2 text-sm">
            <Icon
              name="solar:map-point-wave-outline"
              size="20"
              class="mt-px shrink-0 text-lg text-neutral-800 icon-ms-outlined-location_on"
            ></Icon>
            <span class="line-clamp-2 text-neutral-800">{{
              hotel?.address
            }}</span>
          </div>
        </div>
        <ClientOnly v-if="hotel">
          <LazyCoreLeafletMap :hotel="hotel" />
        </ClientOnly>
      </div>
      <div class="col-span-3 lg:col-span-1">
        <div
          class="overflow-hidden rounded-xl border border-neutral-100 dark:border-neutral-900 lg:h-[480px] lenis lenis-smooth"
        >
          <CoreNearTop />
        </div>
      </div>
    </div>
    <p class="mt-6">* {{ $t("distance_legit") }}</p>
  </ElementsCardLeft>
</template>
<script lang="ts" setup>
import { useHotelStore } from "~/stores/hotel";
import type { TypePlace } from "~/types/TypePlace";
const { hotel } = useHotelStore();
const type_selected = ref<TypePlace>({
  label: "Commute",
  icon: "solar:map-arrow-up-outline",
  google_type: "",
});
const SelectTypePlace = (place: TypePlace) => {
  type_selected.value = place;
};
const { redirect } = useBooking();
const openMap = () => {
  if (hotel?.lat && hotel?.lng) {
    redirect(
      `https://www.google.com/maps/search/${hotel?.name.replaceAll(
        " ",
        "+"
      )}/@${hotel?.lat},${hotel?.lng},15z`
    );
  }
};
</script>
