<script lang="ts" setup>
import { useHotelStore } from "~/stores/hotel";
import type { ScoreBreakdown } from "~/types/Hotel";

const { hotel } = useHotelStore();
interface ScoreBrokenPercent extends ScoreBreakdown {
  percentage: number;
}
const { t } = useI18n();
const scores = computed(() => {
  var s: ScoreBrokenPercent[] = [];
  if (hotel?.score_breakdown) {
    for (let i = 0; i < hotel.score_breakdown.length; i++) {
      s.push({
        name: t("score_brokendown." + hotel.score_breakdown[i].name),
        score: hotel.score_breakdown[i].score,
        percentage: hotel.score_breakdown[i].score * 10,
      });
    }
  }
  return s;
});
const flagUrl = (code: string) => {
  return (
    "https://t-cf.bstatic.com/design-assets/assets/v3.118.0/images-flags/" +
    code.replace(/\b\w/g, (l) => l.toUpperCase()) +
    ".png"
  );
};
</script>
<template>
  <div
    class="mt-6 p-4 w-full bg-white dark:bg-neutral-900 rounded-3xl md:overflow-hidden md:border dark:border-neutral-600 md:p-6"
  >
    <div class="lg:flex lg:flex-row lg:items-center lg:space-x-8">
      <div class="lg:w-64 lg:shrink-0">
        <div
          class="flex flex-col items-center space-y-3 rounded-2xl bg-secondary-50 p-4 sm:flex-row sm:justify-between lg:flex-col lg:text-center"
        >
          <div
            class="flex flex-row items-center space-x-3 lg:flex-col lg:space-x-0 lg:space-y-3"
          >
            <div
              v-if="hotel?.rating_booking"
              :class="{
                'bg-gradient-to-bl from-lime-500 to-green-600':
                  hotel.rating_booking >= 9,
                'bg-gradient-to-bl from-sky-400 to-blue-600':
                  hotel.rating_booking < 9 && hotel.rating_booking >= 6,
                'bg-gradient-to-bl to-amber-700 from-orange-500':
                  hotel.rating_booking < 6 && hotel.rating_booking >= 4,
                'bg-gradient-to-bl to-red-600 from-red-400':
                  hotel.rating_booking < 4,
              }"
              class="flex h-12 w-12 items-center justify-center rounded-full font-bold tracking-tight text-white"
            >
              {{ parseFloat(hotel?.rating_booking + "") }}
            </div>
            <div>
              <div class="text-sm font-bold text-secondary-700">
                {{ hotel?.type_rating?.name }}
              </div>
              <div class="text-xs text-secondary-800">
                <span
                  ><b> {{ hotel?.booking_reviews_count }}</b
                  >&nbsp;{{ t("reviews") }}</span
                >
              </div>
            </div>
          </div>
          <div class="text-xs text-secondary-800">
            Booking.com travellers ratings
          </div>
          <div
            class="flex-center rounded-full bg-green-500 px-4 py-1 font-medium text-xs w-fit text-white mt-4"
          >
            <Icon name="lets-icons:check-fill" size="16"></Icon>
            <span class="mr-1 line-clamp-1"
              >100% {{ $t("verified_reviews") }}</span
            >
          </div>
        </div>
      </div>
      <div class="mt-6 w-full space-y-6">
        <div class="grid gap-y-3 md:grid-cols-3 md:gap-x-6 md:gap-y-7">
          <div
            class="text-neutral-900 dark:text-neutral-100"
            v-for="score in scores"
          >
            <div class="flex justify-between space-x-2 text-base">
              <span class="grow overflow-hidden font-semibold">{{
                score.name
              }}</span
              ><span class="shrink-0 font-bold">{{ score.score }}</span>
            </div>
            <div class="mt-1 h-2 w-full rounded-full bg-neutral-100 dark:bg-neutral-600 lg:mt-2">
              <div
                :class="{
                  'bg-gradient-to-bl from-lime-500 to-green-600':
                    score.score >= 9,
                  'bg-gradient-to-bl from-sky-400 to-blue-600':
                    score.score < 9 && score.score >= 6,
                  'bg-gradient-to-bl to-amber-700 from-orange-500':
                    score.score < 6 && score.score >= 4,
                  'bg-gradient-to-bl to-red-600 from-red-400': score.score < 4,
                }"
                class="h-2 rounded-full"
                :style="{ width: score.percentage + '%' }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <div>
        <div
          class="border-t border-neutral-100 dark:border-neutral-600 py-6"
          v-for="review in hotel?.user_ratings"
        >
          <div>
            <div class="md:flex md:items-start md:space-x-8">
              <div class="shrink-0 md:w-64 lg:w-1/4">
                <div
                  class="flex items-start justify-between md:flex-col md:justify-start md:space-y-3"
                >
                  <div
                    class="font-bold text-neutral-900 dark:text-neutral-100 flex items-center"
                  >
                    <img
                      :alt="review.country_code"
                      width="18"
                      height="18"
                      class="inline-block shrink-0 rounded-full mr-2 overflow-hidden"
                      :src="flagUrl(review.country_code)"
                    />
                    <span> {{ review.author_name }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-4 w-full max-w-full md:mt-0 md:space-y-4">
                <div
                  class="grid grid-cols-[24px_minmax(0,_1fr)] gap-x-3 gap-y-4"
                >
                  <div class="col-span-2 md:col-span-1 md:col-start-2">
                    <div class="flex items-center space-x-2 md:justify-end">
                      <div
                        data-after="&nbsp;/&nbsp;10"
                        class="shrink-0 rounded-full bg-secondary-700 font-semibold text-white after:font-medium after:content-[attr(data-after)] px-2 py-1 text-sm after:text-xxs sm:text-base sm:after:text-xs"
                      >
                        {{ review.rating }}
                      </div>
                    </div>
                  </div>
                  <div class="h-fit w-fit rounded-lg p-1 bg-green-50">
                    <Icon
                      name="tabler:thumb-up"
                      class="shrink-0 text-lg text-green-600"
                      size="28"
                    ></Icon>
                  </div>
                  <div
                    class="self-center text-sm text-neutral-700 dark:text-neutral-100 whitespace-pre-line md:text-base ml-2"
                    v-html="review.text.replaceAll('\\n', '\n')"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
