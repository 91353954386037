<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { useBookingStore } from "~/stores/booking";
import { useHotelStore } from "~/stores/hotel";

const dDate = (date: Date) => {
  return date < new Date();
};
const { locale } = useI18n();
const { hotel } = useHotelStore();
const { form } = storeToRefs(useBookingStore());

const dayjs = useDayjs();
dayjs.locale(locale.value);
const emits = defineEmits(["showPrice"]);
const submitForm = () => {
  if (form.value.datetime.length) {
    emits("showPrice");
  } else {
    openDatetime();
  }
};

const openDatetime = () => {
  const btn_datetime = document.getElementById("btn_datetime");
  if (btn_datetime) {
    btn_datetime.click();
  }
};
const changeDate = (close:any)=>{
  close()
}
</script>
<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <h2
      class="text-xl font-semibold text-neutral-900 dark:text-white md:text-2xl"
    >
      {{ $t("room_title") }}
    </h2>
    <div class="mt-5">
      <form
        @submit.prevent="submitForm"
        class="flex flex-col rounded-2xl lg:flex-row w-full lg:items-center"
      >
        <div class="grow grid-cols-2 gap-4 md:grid w-full">
          <Popover v-slot="{ open }" class="z-20 relative flex w-full flex-1">
            <PopoverButton
              as="div"
              id="btn_datetime"
              class="bg-neutral-50 cursor-pointer w-full rounded-xl hover:bg-secondary-50 dark:bg-neutral-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-inset"
            >
              <div class="flex h-16 items-center px-4 py-3">
                <IconCalender2 class="w-5 h-5 lg:w-7 lg:h-7"></IconCalender2>
                <div class="relative ml-3 min-w-0 grow">
                  <template v-if="form.datetime.length">
                    <div
                      class="flex items-center xl:text-base font-semibold line-clamp-1 dark:text-neutral-50"
                    >
                      <span>
                        {{ dayjs(form.datetime[0]).format("MMMM-DD") }}
                      </span>
                      <span class="font-light text-sm px-2">—</span>
                      <span>
                        {{ dayjs(form.datetime[1]).format("MMMM-DD") }}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="text-sm font-semibold text-neutral-900 dark:text-white"
                    >
                      <div class="truncate">{{ $t("select_dates") }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </PopoverButton>
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="scale-90 opacity-0"
              enter-to-class="scale-100 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="scale-100 opacity-100"
              leave-to-class="scale-90 opacity-0"
            >
              <PopoverPanel
                v-slot="{ close }"
                class="absolute w-screen max-w-[696px] min-h-[300px] p-4 z-40 rounded-xl border border-neutral-100 dark:border-neutral-700 bg-white dark:bg-neutral-800 shadow-lg top-0 -left-6 lg:-left-4 scale-100 opacity-100"
              >
                <vue-tailwind-datepicker
                  :disable-date="dDate"
                  :i18n="locale"
                  class="datepicker-av"
                  v-model="form.datetime"
                  no-input
                  use-range
                  @update:modelValue="changeDate(close)"
                  :auto-apply="true"
                  :formatter="{ date: 'YYYY-MM-DD', month: 'MMM' }"
                  :shortcuts="false"
              /></PopoverPanel>
            </transition>
          </Popover>
          <Popover v-slot="{ open, close }" class="relative flex w-full flex-1">
            <PopoverButton
              as="div"
              class="bg-neutral-50 dark:bg-neutral-900 cursor-pointer w-full rounded-xl hover:bg-secondary-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-inset"
            >
              <div
                class="flex h-16 items-center px-4 py-3"
                id="rooms-control-content"
              >
                <IconPeople class="w-5 h-5 lg:w-7 lg:h-7"></IconPeople>
                <div class="ml-3 min-w-0 grow">
                  <div
                    class="truncate text-sm font-bold text-neutral-900 dark:text-white"
                  >
                    {{ form.adults + form.children }} {{ $t("guests") }},
                    {{ form.rooms }} {{ $t("rooms") }}
                  </div>
                </div>
              </div>
            </PopoverButton>
            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="scale-90 opacity-0"
              enter-to-class="scale-100 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="scale-100 opacity-100"
              leave-to-class="scale-90 opacity-0"
            >
              <PopoverPanel
                class="absolute z-20 rounded-xl border border-neutral-100 dark:border-neutral-700 bg-white dark:bg-neutral-800 shadow-lg -top-1 -right-1 -left-1 p-6 scale-100 opacity-100"
              >
                <CoreFormGuest @close="close"></CoreFormGuest>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>
        <button
          type="submit"
          class="mt-4 shrink-0 truncate whitespace-nowrap rounded-xl bg-secondary-700 px-8 py-5 font-medium text-white hover:bg-secondary-600 focus:outline-none focus-visible:ring-2 active:bg-secondary-600 disabled:bg-secondary-600 lg:ml-4 lg:mt-0"
        >
          <span>{{ $t("check_availibility") }}</span>
        </button>
      </form>
    </div>
  </div>
</template>
