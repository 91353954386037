import { storeToRefs } from "pinia";
import { useBookingStore } from "~/stores/booking";

export default function useAffiliate() {
  const { form } = storeToRefs(useBookingStore());
  const localePath = useLocalePath();
  const object2Query = (options: Object = {}) => {
    var str = [];
    var values = Object.values(options);
    var keys = Object.keys(options);
    for (let u = 0; u < keys.length; u++) {
      str.push(keys[u] + "=" + values[u]);
    }
    return str.join("&");
  };
  const AffQuery = (options: Object = {}) => {
    var query: any = {
      checkin: form.value.datetime[0],
      checkout: form.value.datetime[1],
      group_adults: form.value.adults,
      no_rooms: form.value.rooms,
      group_children: form.value.children,
    };
    return object2Query({
      ...query,
      ...options,
    });
  };
  return { AffQuery };
}
