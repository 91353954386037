<template>
  <div class="p-4 lg:h-full lg:overflow-y-auto lg:p-6 bg-white dark:bg-neutral-900 drop-shadow-sm" >
    <div class="space-y-5 lg:space-y-7" v-if="nearby">
      <template v-for="label in labels">
        <div v-if="nearby[label.name].length" class="text-neutral-800 dark:text-white">
          <h3 class="text-lg font-semibold">
            {{ $t("nearby." + label.name) }}
          </h3>
          <div class="mt-3 space-y-2 lg:mt-5 lg:space-y-4">
            <div v-for="n in nearby[label.name]" class="flex items-end justify-between text-sm ">
              <div class="flex flex-col  dark:text-neutral-200">
                <span class="grow text-xs font-light" v-if="n.key">{{
                  $t(n.key)
                }}</span>
                <h4 class="font-medium text-base">{{ n.name }}</h4>
              </div>
              <span class="ml-4 shrink-0 text-base text-neutral-600 dark:text-neutral-200">{{
                n.distanceLocalized
              }}</span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useHotelStore } from "~/stores/hotel";
import type { Nearby, NearbyModel } from "~/types/Nearby";

const { hotel } = storeToRefs(useHotelStore());
const labels = ref([
  { name: "airports", icon: "material-symbols:connecting-airports" },
  { name: "beaches", icon: "fluent:beach-32-regular" },
  { name: "dining", icon: "icons8:dining-room" },
  { name: "nearby", icon: "healthicons:running-outline" },
  { name: "publicTransport", icon: "maki:bus" },
  { name: "top", icon: "healthicons:temple-outline" },
  { name: "naturalBeauty", icon: "tabler:mountain" },
]);
onMounted(() => {
  if (!hotel.value?.nearby) {
    fetchNearBy();
  }
});
const { locale } = useI18n();
const nearby = computed(() => {
  if (hotel.value?.nearby) {
    if (locale.value == "en") {
      return hotel.value.nearby.payload?.en;
    }
    if (locale.value == "vi") {
      return hotel.value.nearby.payload?.vi;
    }
  }
  return null;
});
const loading = ref(false);
const fetchNearBy = () => {
  if (hotel.value && hotel.value.indent_str) {
    loading.value = true;
    $fetch("/api/nest/nearby", {
      method: "post",
      body: {
        slug: hotel.value?.indent_str,
      },
    })
      .then((res) => {
        if (res && hotel.value) {
          hotel.value.nearby = {
            hotel_id: hotel.value.id,
            payload: res,
          } as NearbyModel;
          saveNearBy();
        }
      })
      .finally(() => (loading.value = false));
  }
};
const saveNearBy = () => {
  if (hotel.value && hotel.value.nearby) {
    useApiFetch("/api/entity/nearby-submit", {
      method: "post",
      body: hotel.value?.nearby,
    }).then((res) => {});
  }
};
</script>
