<script lang="ts" setup>
import { useHotelStore } from "~/stores/hotel";

const { hotel } = useHotelStore();
const { scrollContent } = useLenisClient();
</script>
<template>
  <div
    class="flex h-full flex-col items-start p-3 rounded-2xl border border-neutral-200 lg:p-4"
  >
    <div class="flex w-full items-center space-x-2 text-sm">
      <div
        data-after="&nbsp;/&nbsp;10"
        class="shrink-0 rounded-lg bg-secondary-700 font-bold text-white after:font-medium after:content-[attr(data-after)] p-1.5 text-sm after:text-xs"
      >
        {{ hotel?.rating_booking }}
      </div>
      <div class="truncate font-bold text-neutral-900 dark:text-white">
        {{ hotel?.type_rating?.name }}
      </div>
      <div class="grow truncate text-neutral-800 dark:text-white">
        {{ hotel?.booking_reviews_count?.toLocaleString() }} {{ $t("reviews") }}
      </div>
    </div>
    <div class="mt-2 text-sm text-neutral-600 dark:text-neutral-100 lg:mt-4">
      {{ $t("overall_rating") }}
    </div>
    <div
      class="mt-2 inline-flex max-w-full items-center space-x-2 self-start rounded-lg bg-secondary-100 px-2 py-1 lg:mt-4"
    >
      <Icon
        name="tabler:thumb-up"
        class="shrink-0 text-secondary-700"
        size="20"
      ></Icon>
      <div class="grow truncate text-xs font-medium text-secondary-700">
        {{ $t("guest_rating_location") }}
      </div>
    </div>
    <div class="mt-auto hidden pt-2 lg:block lg:pt-4">
      <button
        @click="scrollContent('reviews')"
        class="text-sm font-medium text-secondary-700 dark:text-secondary-400 hover:text-secondary-600 focus:outline-none focus-visible:text-secondary-600 focus-visible:ring-2"
      >
        {{ $t("more_details") }}
      </button>
    </div>
  </div>
</template>
