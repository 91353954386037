<template>
  <div :style="navigation_entity ? 'height:50px' : ''" ref="nav">
    <div
      class="overflow-hidden"
      :class="{
        'fixed left-0 right-0 top-0 z-30 shadow-md bg-white dark:bg-neutral-900  transition-all ease-in-out':
          navigation_entity,
      }"
    >
      <div class="container mx-auto px-4 sm:px-6 lg:px-8">
        <div
          :class="{ 'border-b border-b-neutral-200': !navigation_entity }"
          ref="emblaNode"
        >
          <div
            class="flex flex-nowrap items-center space-x-4 sm:space-x-8 sm:select-none select-auto"
          >
            <div
              v-for="(hash, i) in hashs"
              class="relative shrink-0 py-3 text-sm sm:text-base font-semibold"
            >
              <button
                :data-href="hash.id"
                @click="scrollContent(hash.id)"
                class="font-medium focus:outline-none focus-visible:text-secondary-500"
                :class="{
                  'text-secondary-800 dark:text-secondary-200': hash.active,
                  'text-neutral-800 hover:text-secondary-600 dark:text-white':
                    !hash.active,
                }"
                :title="hash.label"
              >
                {{ hash.label }}
              </button>
              <div
                class="absolute bottom-0 left-0 right-0 h-0.5 rounded-t-full"
                :class="{
                  'bg-secondary-700 dark:bg-secondary-400': hash.active,
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useConfigStore } from "~/stores/config";
import EmblaCarousel from "embla-carousel-vue";
const status_slide = ref(false);
const [emblaNode, emblaApi] = EmblaCarousel({
  loop: false,
  align: "start",
  dragThreshold: 0,
  breakpoints: {
    "(min-width: 640px)": { active: false },
  },
});
const nav = ref<HTMLElement | null>(null);
const { navigation_entity } = storeToRefs(useConfigStore());
const { t } = useI18n();
const hashs = ref([
  {
    id: "overview",
    label: t("navigation.overview"),
    active: true,
  },
  {
    id: "rooms",
    label: t("navigation.rooms"),
    active: false,
  },
  {
    id: "content",
    label: t("navigation.details"),
    active: false,
  },
  {
    id: "location",
    label: t("navigation.location"),
    active: false,
  },
  {
    id: "facility",
    label: t("navigation.facilities"),
    active: false,
  },
  {
    id: "house_rules",
    label: t("navigation.house_rules"),
    active: false,
  },
  {
    id: "reviews",
    label: t("reviews"),
    active: false,
  },
]);
const setActive = (id: string) => {
  for (let i = 0; i < hashs.value.length; i++) {
    if (hashs.value[i].id == id) {
      hashs.value[i].active = true;
    } else {
      hashs.value[i].active = false;
    }
  }
};
const { scrollContent } = useLenisClient();
onMounted(() => {
  nextTick(() => {
    if (typeof window !== "undefined") {
      const checkScrollType = (e: Event) => {
        const pointOffset = nav.value?.offsetTop || 0;
        const currentScrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;

        const button_hashs = document.querySelectorAll("button[data-href]");
        for (let i = 0; i < button_hashs.length; i++) {
          const el_button = button_hashs[i];

          const id = el_button.getAttribute("data-href");
          if (id) {
            const section = document.getElementById(id);
            const sectionOffset = section?.offsetTop;
            if (sectionOffset && sectionOffset <= currentScrollPosition + 100) {
              setActive(id);
            }
          }
        }

        if (pointOffset + 50 < currentScrollPosition) {
          navigation_entity.value = true;
        } else {
          navigation_entity.value = false;
        }
      };
      window.addEventListener("scroll", checkScrollType);
    }
  });
});
</script>
