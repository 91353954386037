<script setup></script>
<template>
  <div class="grid grid-cols-12 gap-2">
    <div class="col-span-12 lg:col-span-7">
      <div
        class="flex items-center justify-center animate-pulse w-full h-96 bg-gray-300 rounded"
      >
        <svg
          class="w-12 h-12 text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path
            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
          />
        </svg>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-5 flex items-center">
      <div role="status" class="animate-pulse w-full px-4">
        <div class="h-6 bg-gray-200 rounded-sm w-48 mb-4"></div>
        <div v-for="i in 4" class="h-3 bg-gray-200 rounded-sm mb-4"></div>
        <div class="grid grid-cols-2 gap-2 mt-12">
          <div class="col-span-1">
            <div v-for="i in 8" class="h-2 bg-gray-200 rounded-sm mb-2"></div>
          </div>
          <div class="col-span-1">
            <div v-for="i in 8" class="h-2 bg-gray-200 rounded-sm mb-2"></div>
          </div>
        </div>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
