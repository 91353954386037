<script setup lang="ts">
import { useHotelStore } from "~/stores/hotel";

const localePath = useLocalePath();
const { hotel } = useHotelStore();
const place = computed(() => {
  return hotel?.place;
});
const index_hotel = computed(() => {
  return place.value ? 4 : 3;
});
</script>
<template>
  <section class="py-5 md:py-6">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <ol
        class="-ml-2 -mt-2 flex flex-wrap items-center text-sm text-neutral-600 dark:text-neutral-100"
        itemscope
        itemtype="http://schema.org/BreadcrumbList"
      >
        <li
          class="ml-2 mt-2"
          itemscope
          itemprop="itemListElement"
          itemtype="http://schema.org/ListItem"
        >
          <NuxtLink
            :to="localePath('/')"
            class="hover:text-secondary-600 focus:outline-none focus-visible:text-secondary-600 focus-visible:ring-2"
            itemprop="item"
          >
            <span itemprop="name">{{ $t("home") }}</span>
          </NuxtLink>
          <meta content="1" itemprop="position" />
        </li>
        <template v-if="place">
          <li class="ml-2 mt-2">/</li>
          <li
            class="ml-2 mt-2"
            itemscope
            itemprop="itemListElement"
            itemtype="http://schema.org/ListItem"
          >
            <NuxtLink
              :to="localePath('/place/' + place.slug)"
              class="hover:text-secondary-600 focus:outline-none focus-visible:text-secondary-600 focus-visible:ring-2"
              itemprop="item"
            >
              <span itemprop="name">{{
                $t("region", { place: place.name })
              }}</span>
            </NuxtLink>
            <meta content="2" itemprop="position" />
          </li>
          <li class="ml-2 mt-2">/</li>
          <li
            class="ml-2 mt-2"
            itemscope
            itemprop="itemListElement"
            itemtype="http://schema.org/ListItem"
          >
            <NuxtLink
              :to="localePath('/place-hotels/' + place.slug)"
              class="hover:text-secondary-600 focus:outline-none focus-visible:text-secondary-600 focus-visible:ring-2"
              itemprop="item"
            >
              <span itemprop="name">{{
                $t("region_hotel", { place: place.name })
              }}</span>
            </NuxtLink>
            <meta content="3" itemprop="position" />
          </li>
        </template>
        <li class="ml-2 mt-2">/</li>
        <li
          class="ml-2 mt-2 select-none text-neutral-900 dark:text-neutral-400"
        >
          <span>{{ hotel?.name }}</span>
        </li>
      </ol>
    </div>
  </section>
</template>
